import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import throttle from 'lodash/throttle'

// reducers
// import User from './reducers/User/User'
// import Follow from './reducers/Follow/Follow'
// import Notification from './reducers/Notification/Notification'
import Post from './Post/post-reducer'
import User from './User/user-reducer'
// import Explore from './reducers/Explore/Explore'
// import Group from './reducers/Group/Group'
// import Message from './reducers/Message/Message'
// import Setting from './reducers/Setting/Setting'
// import Hashtag from './reducers/Hashtag/hashtag'
import ShoppingCart from './ShoppingCart/shopping-cart-reducer'

const reducers = combineReducers({
    User,
    ShoppingCart,
    //   Follow,
    //   Notification,
    Post,
    //   Explore,
    //   Group,
    //   Message,
    //   Setting,
    //   Hashtag,
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = composeEnhancer(applyMiddleware(thunk, logger))

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

const persistedState = loadState();

const store = createStore(
    reducers,
    persistedState,
    middlewares
)

store.subscribe(throttle(() => {
    saveState(store.getState())
}, 1000))

export default store;
