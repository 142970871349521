import React, { Fragment } from 'react'
import TimeAgo from 'handy-timeago'
import { Card } from 'react-bootstrap'

import ProductActions from './product-actions'
import ProductTop from './product-top'
import ProductImage from './product-image'

const Product = (props) => {
      
    return (
      <Card className='post'>
  
        <ProductTop productDetails={props}/>
  
        <ProductImage productDetails={props}/>
  
        <hr className="img_d_hr" />
        <ProductActions productDetails={{ ...props }} />
        <hr />
        {/* <PostBottom productDetails={{ ...this.props }} /> */}
      </Card>
    )
  
  }

  export default Product