import { getData, setData } from '../../utils/API-utils'
import _ from 'lodash'

export const updateSession = (user) => {
    console.log(user)
    const ws = 'get-user'
    const userData = getData(ws, { uid: user.uid })
    return dispatch => {

        //check if user exists in app db

        if (userData.code === 'NO_USER') { //if user doesnt exist, create it
            let userData = {
                uid: user.uid,
                isSignedIn: true,
                firstname: user.displayName.split(' ')[0],
                completeName: user.displayName,
                email: user.email,
                joined: new Date(),
                email_verified: false,
                account_type: 'public', //public, admin, publisher
                isOnline: true,
                lastOnline: new Date(),
                photoURL: user.photoURL
            }
            let setDataP = setData('create-user', userData)
            console.log(setDataP)
        } else {
            userData = getData(ws, { uid: user.uid })
        }
        //iterate user data
        _.map(userData.data, (data, key) => {
            console.log("Data")
            console.log(data)
            console.log("key")
            console.log(key)
            updateUserData(data.key, data.value)
        })
        //singIn user
        console.log("a ver te intento loggear")
        dispatch(signIn(user.uid))
    }
}

export const signOut = () => {
    return {
        type: 'CHANGE_SESSION_STATE',
        payload: {
            isSignedIn: false,
            id: null
        }
    }
}

export const signIn = (uid) => {
    return {
        type: 'CHANGE_SESSION_STATE',
        payload: {
            isSignedIn: true,
            id: uid
        }
    }
}

export const updateUserData = (data, value) => {
    return {
        type: 'UPDATE_USER_DATA',
        payload: {
            data: data,
            value: value
        }
    }
}

