import React, {Fragment} from 'react'
import Helmet from 'react-helmet';
import { Provider, connect } from 'react-redux';

//Components
import Post from '../products/product'
import MapProducts from '../products/map-products'


const Feed = (props) => {
    let feed = props.feed
    let len = feed.length
    let map_feed = feed.map(f => <Post key={f.postid} {...f} when="feed" />)

    let nothingMssg =
        "Looks like you're new, Follow some to fill up your feed or post from above options!!"

    return (
        <Fragment>
            <div className="posts_div" style={{ marginTop: len == 0 ? 10 : 0 }}>
                <MapProducts posts={map_feed} nothingMssg={nothingMssg} />
            </div>
            {/* {len != 0 && <End />} */}
        </Fragment>
    )
}

const mapStateToProps = state => {
    return ({
        feed: state.Post.feed,
    })
}




export default connect(mapStateToProps)(Feed)