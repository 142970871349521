import React, { Fragment, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { getProfilePicture } from '../../state/Post/post-actions'

import AppLink from '../others/app-link';
import PosterProfileImage from './poster-profile-image'

const PostTop = ({productDetails}) => (
  <div className="p_i">
    <PostTopDetails productDetails={productDetails} />
    {/* <PostOptions
          postDetails={{ ...postDetails }}
          updateDescription={updateDescription}
        /> */}
  </div>
)

const PostTopDetails = ({ productDetails }) => {
  let {
    postid,
    brand,
    brandpicture,
    title,
    description,
    tags,
    imagelink,
    price,
    size,
    quantity
    } = productDetails

  return (
    <Fragment>
      <div className="p_i_img">
        {/* TODO: REMOVE HARDCODED IMAGE*/}
        <PosterProfileImage brandpicture={brandpicture} />
      </div>
      <div className="p_i_1">
        <AppLink
          url={`/profile/${brand}`}
          title={brand}
          label={brand}
        />
        <span className="p_i_1_title" title={title}>
          {title}
        </span>
      </div>
    </Fragment>
  )
}

export default PostTop;