import React, { Fragment, useEffect } from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';
import { Card } from 'react-bootstrap'

import { sessionSelector } from '../../state/User/user-selector'
import { updateSession, signOut } from '../../state/User/user-actions'

const Login = (props) => {

    const firebase = props.firebase

    var isSignedIn = props.isSignedIn
    var anonymousUser = firebase.auth().currentUser

    const uiConfig = {
        autoUpgradeAnonymousUsers: true,
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
                console.log("SIPASO")
                console.log(authResult.user)
                console.log(authResult)
                props.SessionSignIn(authResult.user)
                //return true
            },
            signInFailure: (error) =>{
                console.log("ERRIOR")
                console.log(error)
                console.log(error.code)
                if (error.code == 'firebaseui/anonymous-upgrade-merge-conflict') {
                    return Promise.resolve();
                  }
            }
        },
        tosUrl: '/terms-of-service',
        privacyPolicyUrl: function() {
            window.location.assign('privacy-policy');
          },
    }

    return (
        <div>
            {isSignedIn ?
                (
                    <Redirect to="/" />
                )
                :
                (
                    <div className='loginWrapper'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="https://cdn.shopify.com/s/files/1/0293/9277/files/DISTRESSED_KL_RG_500.jpg?v=1564080499" />
                            <Card.Body>
                                <Card.Title>Register or Login</Card.Title>
                                <Card.Text>
                                    Welcome to Cultivmade, please login to access
                            </Card.Text>
                                <StyledFirebaseAuth
                                    uiConfig={uiConfig}
                                    firebaseAuth={firebase.auth()}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    isSignedIn: sessionSelector(state)
})


const mapDispatchToProps = dispatch => {
    return ({
        SessionSignIn: (user) => dispatch(updateSession(user)),
        SessionSignOut: () => dispatch(signOut())
    })
}



export default connect(mapStateToProps, mapDispatchToProps)(Login);