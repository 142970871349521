import axios, { post, get } from 'axios'


const server = 'https://us-central1-cultivmade-prb.cloudfunctions.net/app';
//const server = 'http://localhost:5001/cultivmade-prb/us-central1/app'


/**
 * Dispatcher helper for dispatching data retrieved from URL
 *
 * @param {String} type Dispatch type
 * @param {String} url /api/URL to get data from
 * @param {Object} data data requested with the url
 */
export const dispatchHelper = (type, url, data = {}) => {
  return dispatch =>
    post(`${server}/${url}`, data)
      .then(p => dispatch({ type, payload: p.data }))
      .catch(e => console.log(e))
}

export const getData = (ws , data) => {
  get(`${server}/${ws}`, {params: data})
    .then(p => p.data)
    .catch(e => console.log(e))
}

export const setData = (ws, data) => {
  post(`${server}/${ws}`, {userData: data})
    .then(p => p.data)
    .catch(e => console.log(e))
}
