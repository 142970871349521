//LIBRARIES
import React, { Fragment, useEffect } from 'react';
import { Provider, connect } from 'react-redux';
import firebase from 'firebase'

//COMPONENTS
import AppRoutes from './components/routes/routes'

//FUNCTIONS AND ACTIONS
import store from './state/store'
import { signIn, signOut, updateSession } from './state/User/user-actions'

//import { shortener } from './utils/utils'

//import { uData } from './utils/utils'


window.React = React
window.store = store

console.log(store.getState())

const firebaseConfig = {
  apiKey: "AIzaSyBeuaOlLhHmOLUjRRlo0DCSEvKA-ntwiRw",
  authDomain: "cultivmade-prb.firebaseapp.com",
  databaseURL: "https://cultivmade-prb.firebaseio.com",
  projectId: "cultivmade-prb",
  storageBucket: "cultivmade-prb.appspot.com",
  messagingSenderId: "907042327478",
  appId: "1:907042327478:web:e08e89235150726cf2cf17"
}

if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig)
}

// Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
// throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
// to avoid the entire page breaking, without having to do a check at each usage of Storage.
if (typeof localStorage === 'object') {
  try {
      localStorage.setItem('localStorage', 1);
      localStorage.removeItem('localStorage');
  } catch (e) {
      Storage.prototype._setItem = Storage.prototype.setItem;
      Storage.prototype.setItem = function() {};
      alert('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.');
  }
}

var user = null

firebase.auth().onAuthStateChanged(u => {
  if (u) {
    user = u
    store.dispatch(updateSession(user.uid))
  } else {
    store.dispatch(signOut())
  }
});


const App = () => {
  return (
    <Provider store={store}>
      <AppRoutes firebase={firebase}/>
    </Provider>
  );
}

export default App;
