import React from 'react';
import { Container, Navbar, Nav, NavDropdown, Row, Col, ListGroup } from 'react-bootstrap'
import { signOut } from '../../state/User/user-actions'
import { connect } from 'react-redux'

import MaterialIcon from '../others/material-icon'


const Header = (props) => {
  const firebase = props.firebase
  const menuSignOut = () => {
    firebase.auth().signOut().catch(err => {
      console.log(err)
    })
    props.menuSignOut()
  }
  

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed='top'>
      <Navbar.Brand href="/">Cultivmade</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        {/* <Nav className="mr-auto">
          <Nav.Link href="#features">Features</Nav.Link>
          <Nav.Link href="#pricing">Pricing</Nav.Link>
          <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown>
        </Nav> */}
        <Nav className="ml-auto" >
          <Nav.Link href="/shopping-cart"><MaterialIcon icon='shopping_cart'/></Nav.Link>
          <Nav.Link onClick={menuSignOut}>Logout</Nav.Link>
        </Nav> 
      </Navbar.Collapse>
    </Navbar>
  )
}

const mapDispatchToProps = (dispatch) => {
  return({
    menuSignOut: () => dispatch(signOut())
  })
}


export default connect(null,mapDispatchToProps)(Header);