//Libraries
import React, { Fragment, useEffect } from 'react'
import Helmet from 'react-helmet';
import { Provider, connect } from 'react-redux';

//Components
import Feed from './feed'

//Functions and Actions
import { dispatchHelper} from '../../utils/API-utils';


const Home = (props) => {

    let getFeed = props.getFeed

    useEffect(() => {
        getFeed()
    }, [])

    return (
        <Fragment>
            <Title value="Home" />
            <Feed />
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    getFeed: () => {
        dispatch(dispatchHelper('GET_FEED', 'get-post-sampleData'))
    }
})

const Title = ({ value, desc, un }) => {
    // for removing app default description in the topHeader.hbs
    // to avoid two meta tags for description
    // new d('meta[data-desc-src="hbs"]').remove()

    return (
        <Helmet>
            <title>
                {un ? `(${un})` : ''} {`${value}`} • Cultivmade
        </title>
            <meta name="description" content={desc} />
        </Helmet>
    )
}

export default connect(null, mapDispatchToProps)(Home)