import InitialState from './initialState'
import * as methods from './shopping-cart-methods'

export default (state = InitialState, action) => {
  let py = action.payload

  switch (action.type) {
    case 'ADD_ITEM_TO_CART':
      return {
        ...state,
        items: methods.addItem(py.item, state.items) 
      }
      break
    default:
      return state
  }
}
