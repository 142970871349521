export default {
  uid: null,
  isSignedIn: false,
  firstname: 'User',
  completeName: null,
  email: null,
  joined: null,
  email_verified: false,
  account_type: 'public', //public, admin, publisher
  isOnline: false,
  lastOnline: null,
  photoURL: null
}
