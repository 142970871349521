import React, { useEffect } from 'react'
import { getProfilePicture } from '../../state/Post/post-actions'
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';

const PosterProfileImage = (props) => {
     
    const brandpicture = props.brandpicture
    // const getProfilePicture = props.getProfilePicture

    // useEffect(() => {
    //     getProfilePicture(props.user)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [])
    return (
        <Image fluid roundedCircle src={brandpicture}/>
    )
}

// const mapDispatchToProps = (dispatch) => ({
//     getProfilePicture: (user) =>{
//         dispatch(getProfilePicture(user))
//     } 
// })

// const mapStateToProps = (state, ownProps) => ({
//     profilePictureURL: state.Post.feed.find(post => post.user === ownProps.user).profilePictureURL
// })

// export default connect(mapStateToProps,mapDispatchToProps)(PosterProfileImage);

export default PosterProfileImage;