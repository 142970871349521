import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';

const AppLink = (props) => {
    let { label, url, children, ...newProps } = props
  
    return (
      <Fragment>
        <Link to={url} {...newProps}>
          {children ? children : label}
        </Link>
      </Fragment>
    )
  }

export default AppLink;