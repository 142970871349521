import React, { Fragment } from 'react';
import { Container, Navbar, Nav, NavDropdown, Row, Col, ListGroup } from 'react-bootstrap'
import { connect } from 'react-redux';

import Header from './header'
import SideBar from './sidebar'

const PrivateLayout = (props) => {
    return (
        <Fragment>
            <Header firebase={props.firebase}/>
            <div className='layout'>
                <Container>
                    <Row >
                        <Col className="d-none d-md-block" md={3}>
                            <Row>
                                <SideBar />
                            </Row>
                        </Col>
                        <Col sm={12} md={9}>
                            <Row>
                                {props.children}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

const PublicLayout = (props) => {
    return (
        <div > 
           {props.children}
        </div>
    )
}

export {PrivateLayout, PublicLayout}