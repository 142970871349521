import React from 'react';
import classNames from 'classnames';
import { Image } from 'react-bootstrap';


const PostImage = ({ productDetails }) => {
  var state = {
    showImage: false,
  }

  const _toggle = what => { return ({ [what]: !this.state[what] }) }

  let {
    post_id,
    post_time,
    description,
    imagelink,
    filter,
    username,
    tags_count,
  } = productDetails

  let { showImage } = state

  return (
    <div>
      <div className="p_o">
        <div className="p_actual" spellCheck="false">
          <div
            className="p_abt"
            style={{ marginBottom: description ? '10px' : null }}
          >
            {/* <p>
                <ToTags str={`${description}`} />
              </p> */}
          </div>

          <Image
            src={imagelink}
            className={classNames('p_img', filter)}
            onClick={() => this._toggle('showImage')}
            rounded fluid
          />

          {/* <PostTags post_id={post_id} tags_count={tags_count} /> */}
        </div>
      </div>

      {/* TODO: implement */}
      {/* {showImage && (
          <ImageTheatre
            imgSrc={`/posts/${imgSrc}`}
            filter={filter}
            username={username}
            time={post_time}
            link={`/post/${post_id}`}
            back={() => this._toggle('showImage')}
          />
        )} */}
    </div>
  )

}

export default PostImage;