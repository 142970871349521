import React from 'react'
import { Card, Row, Col, Container} from 'react-bootstrap'
import { connect } from 'react-redux'

import _ from 'lodash'

const ShopingCartPage = (props) => {
    return (
        <Container>
            <CartItems items={props.items} />
        </Container>
    )
}

const CartItems = (props) => {
    const items = props.items
    return items.length > 0 ?
    (_.map(items, (item, index) => {
        return (
            <CartItem item={item} />
        )
    }))
    :
    (<div>
        No Items in your shopping cart
    </div>)

}

const CartItem = (props) => {
    return (
        <Card>
            <Row >
                <Col sm={8} >{props.item.name}</Col>
                <Col sm={2} >{props.item.price}</Col>
                <Col sm={2} >{props.quantity}</Col>
            </Row >
        </Card>
    )
}

const mapStateToProps = (state) => ({
    items: state.ShoppingCart.items
})


export default connect(mapStateToProps)(ShopingCartPage);
