import React from 'react'
import { connect } from 'react-redux'

import ProductLike from './product-like'

const mapDispatchtoProps = dispatch => ({
    incrementWhat: (what) => {
        dispatch("IncrementWhat")
    },
    decrementWhat: (what) => {
        dispatch("DecreaseWhat")
    }
})

const mapStatetoProps = state => {
    return {
        likes_count: state.Post.feed.likes_count,
        shares_count: state.Post.feed.shares_count,
        post_id: state.Post.feed.post_id,
        user: state.Post.feed.user,
        post_time: state.Post.feed.post_time,
    }
}

const ProductActions = (props) => {

    let { likes_count, shares_count, post_id, user, post_time } = props


    // componentDidMount = () => {
    //   let {
    //     postDetails: { likes_count, shares_count },
    //   } = this.props
    //   this.setState({ likes_count, shares_count })
    // }

    let childProps = {
        postDetails: { post_id, user },
        incrementWhat: props.incrementWhat,
        decrementWhat: props.decrementWhat,
    }

    return (
        <div>
            <div className="p_a">
                <div className="p_do">
                    <ProductLike {...childProps} />
                    {/* <PostBookmark postDetails={{ post_id, when }} />
                    <PostShare {...childProps} /> */}
                </div>

                {/* <div className="p_did">
                    <ShowLikes
                        post_id={post_id}
                        likes_count={likes_count}
                        decrementLikes={() => this.decrementWhat('likes_count')}
                    />

                    <ShowShares
                        post_id={post_id}
                        shares_count={shares_count}
                        decrementSharers={() => this.decrementWhat('shares_count')}
                    />
                </div> */}
            </div>
        </div>
    )

}

export default connect(mapStatetoProps, mapDispatchtoProps)(ProductActions);