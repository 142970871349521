import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Container, Navbar, Nav, NavDropdown, Row, Col, ListGroup } from 'react-bootstrap'

import MaterialIcon from '../others/material-icon'

const SideBar = (props) => {
  const user = props.user

  let username = user.firstname
  let profile = `/profile/${username}`

  return (
    <div className='sidebar'>
      <ListGroup >
        <ListGroup.Item action href="/">{username}</ListGroup.Item> {/*TODO: should redirect to profile page*/}
        <ListGroup.Item action href="/" >Home</ListGroup.Item>
        {/* <ListGroup.Item action href="Explore" >Explore</ListGroup.Item>
        <ListGroup.Item action href="notifications">notifications</ListGroup.Item>
        <ListGroup.Item action href="Messages">Messages</ListGroup.Item>
        <ListGroup.Item action href="Edit profile" >Edit profile</ListGroup.Item> */}
      </ListGroup>
      {/* <SidebarBottom /> */}
    </div>
  )
}

const SidebarBottom = () => {
  var state = {
    showOptions: false,
  }

  const toggleOptions = () => {
    return (
      { showOptions: !state.showOptions }
    )
  }

  const style = {
    bottom: '1em',
    position: 'fixed',
    width: '14em',
  }


  return (
    <Fragment>
      <Nav className="justify-content-center" fill style={style}>
        <Nav.Item>
          <a href="/logout">Logout</a>
        </Nav.Item>
        <Nav.Item>
          <a href="/help">Help</a>
        </Nav.Item>
        <Nav.Item>
          <a
            href="#"
            className="toggle-sb-options"
            onClick={toggleOptions}
          >
            <MaterialIcon icon="more_horiz" />
          </a>
        </Nav.Item>
      </Nav>

      {/* {showOptions ? <SidebarOptions /> : null} */}
    </Fragment>
  )

}

const mapStateToProps = (state) => ({
  user: state.User
})


export default connect(mapStateToProps)(SideBar);