import React, { Fragment } from 'react'
import { Card, Container, Tabs, Tab, Form, Col, Button, InputGroup } from 'react-bootstrap'

import { convertCSVtoJSON } from '../../utils/product-import'
import { Formik } from 'formik'
import * as yup from 'yup'

var file = undefined
const convertButton = () => {
    if (file === undefined) {
        alert('please select a file')
    } else {
        convertCSVtoJSON(file)
    }
}

const ProductImportPage = (props) => {

    return (
        <Fragment>
            <Container>
                <Tabs defaultActiveKey="Product Import" >
                    <Tab eventKey="Product Import" title="Product Import">
                        <UploadSingleProduct />
                    </Tab>
                    <Tab eventKey="CSV Upload" title="CSV Upload">
                        <CSVUploadForm />
                    </Tab>
                </Tabs>
            </Container>
        </Fragment>
    )
}

const UploadSingleProduct = (props) => {
    const schema = yup.object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        username: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        zip: yup.string().required(),
        terms: yup.bool().required(),
    });
    return (


        <Formik
            validationSchema={schema}
            onSubmit={console.log}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <FormSection
                            render={() => {
                                return (
                                    <Fragment>
                                        <Form.Group as={Col} sm={12} controlId="productId">
                                            <Form.Label>Product Id</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="productId"
                                                value={values.productId}
                                                onChange={handleChange}
                                                isValid={touched.productId && !errors.productId}
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12} controlId="title">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                isValid={touched.title && !errors.title}
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="12" controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control as="textarea" rows="5"
                                                type="textarea"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                isValid={touched.description && !errors.description}
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Fragment>
                                )
                            }}
                        />
                        <Button type="submit">Submit form</Button>
                    </Form>
                )}
        </Formik>

    )
}

const CSVUploadForm = (props) => {
    const schema = yup.object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        username: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        zip: yup.string().required(),
        terms: yup.bool().required(),
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={console.log}
            initialValues={{
                file: undefined,
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Row>
                            <FormSection
                                render={() => (
                                    <Fragment>
                                        <Form.Group as={Col} sm="12" controlId="description">
                                            <Form.Label>Import your CSV</Form.Label>
                                            <Form.Control
                                                placeholder="Select your CSV file"
                                                type="file"
                                                name="file"
                                                onChange={(e) => file = e.target.files[0]}
                                            />
                                        </Form.Group>
                                        <Button type="submit">Import</Button>
                                    </Fragment>
                                )
                                }
                            />
                        </Form.Row>
                    </Form>
                )}
        </Formik>
    )
}

const FormSection = ({ render: Component }) => {
    return (
        <Card className="formSection">
            <Container>
                <Form.Row>
                    <Component />
                </Form.Row>
            </Container>
        </Card>
    )
}

export default ProductImportPage;