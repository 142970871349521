import React, { Fragment } from 'react';

const MapProducts = ({ posts, nothingMssg }) => {
    let len = posts.length
  
    return (
      <Fragment>
        {len == 0 ? (
          // <Nothing mssg={nothingMssg} /> 
          'nada'
        ) : (
            // <FadeIn duration="500ms">{posts}</FadeIn>
            <div>{posts}</div>
          )}
      </Fragment>
    )
  }

  export default MapProducts;