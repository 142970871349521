import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux'
import MaterialIcon from '../others/material-icon'
import { like, unlike } from '../../state/Post/post-actions'

const PostLike = (props) => {

  // componentDidMount = async () => {
  //   let {
  //       postDetails: { post_id },
  //     } = this.props,
  //     { data: liked } = await post('/api/liked-or-not', { post: post_id })
  //   await this.setState({ liked })
  // }
  const liked = props.liked

  const like = props.post_like
  const unlike = props.post_unlike

  return (
    <Fragment>
      <div className="p_Like_wra">
        {liked ? (
          <span
            className="p_like p_unlike_icon"
            data-tip="Unlike"
            onClick={unlike}
          >
            <MaterialIcon icon="favorite" />
          </span>
        ) : (
            <span
              className="p_like p_like_icon"
              data-tip="Like"
              onClick={like}
            >
              <MaterialIcon icon="favorite_border" />
            </span>
          )}
      </div>
    </Fragment>
  )

}

const mapStateToProps = state => {
  return {
    liked: state.Post.feed.liked
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    post_like: like,
    post_unlike: unlike
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostLike);