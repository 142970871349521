import InitialState from './initialState'
//import * as methods from './methods'

export default (state = InitialState, action) => {
  let py = action.payload

  switch (action.type) {
    case 'CHANGE_SESSION_STATE':
      return {
        ...state,
          id: py.sessionId,
          isSignedIn: py.isSignedIn,
      }
      break
    case 'UPDATE_USER_DATA':
      return {
        ...state,
        [py.data]: py.value
      }
      break
    //TODO: CREATE A GENERIC REDUCER THAT UPDATES WITH MULTIPLE FIELDS, SO I CAN USE IT INSTEAD OF CALLING UPDATE_USER_DATA MULTIPLE TIMES
    default:
      return state
  }
}
