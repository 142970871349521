import React from 'react'
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux'
import { BrowserRouter, Link } from 'react-router-dom';
import _ from 'lodash'

//Selectors
import { sessionSelector } from '../../state/User/user-selector'


//Components
import { PublicLayout, PrivateLayout } from '../layout/layout'
import Login from '../login/login'
import Home from '../home/home'
import ShoppingCart from '../shoppingCart/shoppingCart'
import PrivacyPolicy from '../legal/privacyPolicy'
import TermsOfService from '../legal/termsOfService'
import ProductImportPage from '../product-import/product-import-page'

const routes = {
    /* Description:
    component: have the react component which should render
    path: BrowserRoute that will be passed as Route
    privacy: have 3 possible values:
        1) public: public routes, like privacy notice, information, etc. will get a public layout
        2) private: in order to visit this routes the user needs to be logged in, otherwise it will be redirected to login
        3) session: session routes: login, logout.
    */

    Home: {
        component: Home,
        path: '/',
        privacy: 'private'
    },
    Login: {
        component: Login,
        path: '/login',
        privacy: 'session'
    },
    PrivacyPolicy: {
        component: PrivacyPolicy,
        path: '/privacy-policy',
        privacy: 'public'
    },
    TermsOfService: {
        component: TermsOfService,
        path: '/terms-of-service',
        privacy: 'public'
    },
    ShoppingCart: {
        component: ShoppingCart,
        path: '/shopping-cart',
        privacy: 'private'
    },
    ProductImport: {
        component: ProductImportPage,
        path: '/product-import',
        privacy: 'private'
    }
}


const AppRoutes = (props) => {
    return (
        <BrowserRouter>
            <Switch>
                {_.map(routes, (route, key) => {
                    const { component: Component, path, privacy } = route
                    return (
                        <Route
                            exact
                            path={path}
                            key={key}
                            render={
                                () => {
                                    switch (privacy) {
                                        case 'public':
                                            console.log(`ruta: ${privacy}`)
                                            return (
                                                <PublicLayout>
                                                    <Component firebase={props.firebase} />
                                                </PublicLayout>
                                            )
                                            break
                                        case 'private':
                                            console.log(`ruta: ${privacy}`)
                                            {/*TODO: put component as render props */}
                                            return (
                                                props.isSignedIn ? (
                                                    <PrivateLayout firebase={props.firebase}>
                                                        <Component firebase={props.firebase} />
                                                    </PrivateLayout>
                                                ) : (
                                                        <Redirect to="/login" />
                                                    )
                                            )
                                            break
                                        case 'session':
                                            console.log(`ruta: ${privacy}`)
                                            return (
                                                props.isSignedIn ? (
                                                    <Redirect to="/" />
                                                ) : (
                                                        <PublicLayout>
                                                            <Component firebase={props.firebase} />
                                                        </PublicLayout>
                                                    )
                                            )
                                            break
                                        default:
                                            console.log("llegue al default ptm")
                                    }
                                }
                            }
                        />
                    )
                })}
            </Switch>
        </BrowserRouter>
    )
}



// ----------------------------------------------------------------------------

const mapStateToProps = (state) => ({
    isSignedIn: sessionSelector(state)
})


export default connect(mapStateToProps)(AppRoutes);